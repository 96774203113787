export  const jobData = [
    {
        p_id: 1,
        name: 'International Voice Technical/Customer support',
        desc: 'Freshers 2019, 2020, 2021, and 2022 are eligible Experience up to 5 years in international voice process Customer/ Technical support are eligible',
        loc: 'CHENNAI',
        Eligiblity: 'Fresher',
    },
    {
        p_id: 2,
        name: 'International Non-Voice : Technical/Customer support.',
        desc: 'Candidates should have Good English communication skills.',
        loc: 'CHENNAI',
        Eligiblity: 'Fresher',
    },
]