import react from "react";
import "./service.css";
import "./header.css";
import "./digital.css";
import SocialMediaMarketing from "./assest/seltam_img/SocialMediaMarketing.png";
import seo from "./assest/seltam_img/seo.jpg";
import ContentMarketing from "./assest/seltam_img/ContentMarketing.jpg";
import InfluencerMarketing from "./assest/seltam_img/InfluencerMarketing.jpg";
import MobileAdvertising from "./assest/seltam_img/MobileAdvertising.jpg";
import othermarketing from "./assest/seltam_img/othermarketing.jpg";
import Footer from "./footer.js";
import ScrollToTop from "./scrolltop";

function Digitalmarketing() {
  return (
    <div>
      <ScrollToTop />
      <div class="container-fluid dig_img text-center  d-flex justify-content-center flex-column">
        <h2 class="display-5 service-heading text-white mt-5">
          Digital Marketing
        </h2>
      </div>
      <div class="container my-5 text-center">
        <h3 class="below-h2-1">Going Digital and Getting Results</h3>
        <div class="d-flex justify-content-center my-4">
          <div class="border-down"></div>
        </div>
        <p class="wwe-p">
          Seltam creates valuable content that matters for your customers, uses
          the cost-effective benefits of digital marketing, reaches more
          customers with SEO services, uses social media marketing to reach
          current and potential customers and joins channels that drive your
          sales and business.
        </p>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white">SEO</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                SEO works to make your business optimised for search engines,
                like Google and Bing. It’s all about moving you up the search
                engine results page rankings to have better visibility for users
                searching for your website.
              </p>
              <div class="text-center">
                <div class="digi-border"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 bg-blue-digital-marketing-1"></div>
          {/*  */}
          <div class="col-lg-6 bg-blue-digital-marketing-2"></div>
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white">Social Media Marketing</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                With social platforms like Facebook, Twitter and Instagram (to
                name a few) ever growing, businesses online need to invest in
                social media marketing in order to grow their following and
                reach a whole new pool of potential customers.
              </p>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white">Content Marketing</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                This style of marketing is a little different. It's not so
                much about directly marketing products and services to
                customers, but rather creating enriching and valuable content
                that enhances customer experience.
              </p>
            </div>
          </div>
          <div class="col-lg-6 bg-blue-digital-marketing-3"></div>
          {/*  */}
          {/*  */}
          <div class="col-lg-6 bg-blue-digital-marketing-4"></div>
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white">Influencer Marketing</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                It has become more and more popular in recent years, through
                sites like Instagram and YouTube, where companies will enlist a
                well-known influencer, to promote their products or services on
                their social pages or affiliate websites
              </p>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white">Mobile Advertising</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                Businesses all over the world are shifting more of their
                advertising budget into mobile because consumers spend more time
                on their mobile devices more than any other device.
              </p>
            </div>
          </div>
          <div class="col-lg-6 bg-blue-digital-marketing-5"></div>
          {/*  */}
          {/*  */}
          <div class="col-lg-6 bg-blue-digital-marketing-6"></div>
          <div class="col-lg-6 bg-blue-digital-marketing d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex flex-column justify-content-center align-items-center">
              <h3 class="below-h2-1-white text-left">All Other Marketing</h3>
              <p class="wwe-p-white text-center my-2 px-5">
                Maketing like SEM, PPC, Email marketing, Viral marketing,
                Television and radio these are also playing major roles in
                marketing and we are here to support your business our marketing
                strategy.
              </p>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
}
export default Digitalmarketing;
