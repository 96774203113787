import react from "react";
import "./header.css";

function Wwe() {
  return (
    <div id="">
      <div class="container my-5 d-flex justify-content-center">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10 mt-5">
            <div class=" border-box my-4 p-sm-6">
              <h3 class="wwe-h3">World-class creative</h3>
              <h3 class="wwe-h3">No agency overhead</h3>
              <div class="text-center d-flex justify-content-center align-items-center my-4">
                <div class="border-down"></div>
              </div>
              <h3 class="wwe-p">
                {" "}
                We are a one-stop company offering all types of website
                solutions, software solutions, Mobile Applications, Payroll solutions
                Seltam Technologies is a professional, unique and highly
                creative web designing company in
                Chennai.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Wwe;
