// import logo from './logo.svg';
import Header from "./header";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink } from "react-router-dom";
// import Main from "./main";
import About from "./about_us";
import 'antd/dist/antd.css'; 
import Services from "./services";
// import R_and_s from "./recuitmentandstaffing";
import Contact from "./contact";
import Webappdev from "./webappdev";
import Software from "./softwaredev";
import Digitalmarketing from "./digitalmarketing";
import Payroll from "./payroll";
import Recruitment from "./recruitment";
import Career from "./career.js";
import MobileApp from "./mobileapp.js";
import Footer from "./footer.js";
import Navbar from "./navbar";
import Development from "./development";
import R_and_s from "./R_and_s"
import { useEffect } from "react";
import ApplyJobs from "./applynow/apply";
import Form from "./applynow/form/form";


function App() {
  useEffect(()=>{console.log("Refresh")});
  return (  
    [
    <BrowserRouter>
      <div>
        <div>
          <Navbar/>
        </div>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/about" element={<About />} />
          <Route path="/deve" element={<Development />} />
          <Route path="/R_and_s" element={<R_and_s />} />
          <Route path="/webapp" element={<Webappdev />} />
          <Route path="/software" element={<Software />} />
          <Route path="/mobileapp" element={<MobileApp />} />
          <Route path="/service" element={<Services />} />
          <Route path="/Payroll" element={<Payroll />} />
          <Route path="/recruit" element={<Recruitment />} />
          <Route path="/digitalmarketing" element={<Digitalmarketing />} />
          <Route exact path="/applyJobs" element={<ApplyJobs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/form" element={<Form />} />
        </Routes>
        <div>
        <Footer />
        </div>
      </div>
    </BrowserRouter>]
  );
}

export default App;
