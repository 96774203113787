import react, { useEffect } from "react";
import "./App.css";
import "./main.css";
import "./header.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import About from "./about_us";
import Services from "./services";
// new component
import Banner from "./banner.js";
import Wwe from "./whatwedo.js";
import Third from "./thirdcontainer.js";
import Ourproccess from "./ourproccess.js";
import Ourservice from "./ourservices";
import ScrollToTop from "./scrolltop";
import Cta from "./CTA";

function Header() {
  var go_top = () => {
    window.scrollTo(0, 0);
  };

 useEffect(() => {
  var mybutton = document.getElementById("scroll-top-btn");
  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  window.onscroll = () => {
    scrollFunction();
  };
 })

  return (
    <section class="text-center">
      <ScrollToTop />
      {/* banner starts */}
      <Banner />
      {/* banner  ends*/}
      {/* what we do */}
      <Wwe />
      {/* what we do */}
      {/* third container */}
      <Third />
      {/* third container */}
      {/* our procccess */}
      <Ourproccess />
      {/* our procccess */}
      {/* our services */}
      <Ourservice />
      {/* our services */}
      {/* footer */}
      {/* footer */}
      <Cta />
      <div>
        <button id="scroll-top-btn" onClick={go_top} class="navigation-btn">
          <i class="fas fa-arrow-up"></i>
        </button>
      </div>
    </section>
  );
}
export default Header;
