import react from "react";
import "./service.css";
import "./webapp.css";
import "./header.css";
import websiteredesigning from "./assest/seltam_img/websiteredesigning.jpg";
import responsivewebdesign from "./assest/seltam_img/responsivewebdesign.jpg";
import customwordpresswebdesign from "./assest/seltam_img/customwordpresswebdesign.jpg";
import Navbar from "./navbar";
import Footer from "./footer";
import ScrollToTop from "./scrolltop";
import responsive_image_1 from "./assest/seltam_img/responsive_image_1.jpg";

function Webappdev() {
  return (
    <div class="">
      <ScrollToTop />
      <div class=" web_img text-center  d-flex justify-content-center flex-column">
        <h2 class="display-5 service-heading text-white mt-5">Web Design</h2>
      </div>
      <div class="container-fluid mt-5 text-center">
        <p className="fifth-h1-2">
          Affordable &amp; Fast Web Designing Services
        </p>
        <div class="d-flex justify-content-center">
          <div class="border-down"></div>
        </div>
        <p class="wwe-p px-2 mt-3">
          All websites are different, and each site requires a customized
          approach to maximize its potential. Seltam provides Custom Web Design,
          Search Engine <br></br>Marketing, and ongoing Hosting and Maintenance.
        </p>
      </div>
      <div class="my-5">
        <div class="container-fluid">
          <div class="row">
            {/*  */}
            <div class="col-lg-7 d-flex flex-column align-items-center justify-content-center">
              <h3 class="text-left">Custom WordPress Web Design</h3>
              <div>
                <div class="border-orange"></div>
              </div>
              <div class="px-5 py-2 d-flex flex-column align-items-center justify-content-center">
                <p class="wwe-p">
                  WordPress is one of the easiest and most robust ways to share
                  your business on the web. WordPress contains plugin
                  architecture and a template system, so you can customize any
                  website to fit your business, blog, portfolio, or online store
                </p>
                <p class="wwe-p">
                  The best in-house WordPress experts help you develop clean and
                  responsive websites in a seamless process-driven manner. The
                  WordPress platform enables all users to independently update
                  the website with new content without any problems.
                </p>
              </div>
            </div>
            <div class="col-lg-5 my-3">
              <img src={customwordpresswebdesign} width="500px" class="img-fluid blend-img"></img>
            </div>
            {/*  */}

             {/*  */}
             
             <div class="col-lg-7 d-flex flex-column align-items-center justify-content-center">
              <h3 class="text-left">Website Redesigning Services</h3>
              <div>
                <div class="border-orange"></div>
              </div>
              <div class="px-5 py-2 d-flex flex-column align-items-center justify-content-center">
                <p class="wwe-p">
                Website redesign services help to transform a website's existing look and feel via UX and UI revamp. Get your website a modern look with guaranteed enhancements, Smooth content migration and Well- maintained SEO equity. Creative Designs & Responsive Website is the need of the hour for business development which ultimately increases your revenue.
                </p>
              </div>
            </div>
            <div class="col-lg-5 my-3">
              <img src={websiteredesigning} width="500px" class="img-fluid blend-img "></img>
            </div>
            {/*  */}

              {/*  */}
              <div class="col-lg-7 d-flex flex-column align-items-center justify-content-center">
              <h3 class="text-left">Responsive Web Design & Development</h3>
              <div>
                <div class="border-orange"></div>
              </div>
              <div class="px-5 py-2 d-flex flex-column align-items-center justify-content-center">
                <p class="wwe-p">
                Responsive web design is an approach that allows design across various devices (mobile, desktop, tablet, etc.) and suggests design should respond to the user's behavior based on screen size, platform and orientation.
                </p>
                <p class="wwe-p">
                A responsive website has a fluid and flexible layout which offers an optimized browsing experience. We develop websites using advanced technologies that loads quickly, is easy to navigate, and has an engaging design.
                </p>
              </div>
            </div>
            <div class="col-lg-5 my-3">
              <img src={responsive_image_1} width="500px" class="img-fluid blend-img"></img>
            </div>
            {/*  */}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Webappdev;
