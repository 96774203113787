import { createRef,  useState, } from 'react';
import { Modal, Form, Input, Row, Col, message } from 'antd' 
import { useForm } from 'antd/lib/form/Form';
import { ApplyJobs } from '../service/apply';



  const FormComp = ( {setModal, isModal} ) => {
    const form = useForm();
    const formRef = createRef();
    const [ respData, setrespData ] = useState();
    const [ respcode, setrespcode ] = useState();
    
    function closeModal(values) {
      setModal(false);
    }
   
    const SendData = ( values ) => {
        ApplyJobs(values, setrespcode, setrespData);
        if ( respcode === 201 ) {
            closeModal();
        }
    }

    return(
       <>
        <Modal width={700} form={form} visible={isModal} onCancel={closeModal} footer={false}>
        <Form ref={formRef} layout='vertical' style={{padding: '20px'}} onFinishFailed={(values) => {closeModal(values)}} onFinish={(values) => {SendData(values)}}>
           <Row gutter={16}>
               <Col span={12}>
                 <h5>Register</h5>
                 <Form.Item name='name' label='Name'  rules={[{ required: true,  }]}>
                     <Input placeholder='Name'/>
                 </Form.Item>
                 <Form.Item label='Mail'  name='mail' rules={[{ required: true, type: 'email' }]} >
                     <Input placeholder='mail' />
                 </Form.Item>
                 <Form.Item label='Age'  name='age' rules={[{ required: true,  }]}>
                     <Input placeholder='age'/>
                 </Form.Item>
                 <Form.Item label='Qualification'  name='qual' rules={[{ required: true,  }]}>
                     <Input placeholder='qualification'/>
                 </Form.Item>
                 <Form.Item label='Expected CTC'  name='e_ctc' rules={[{ required: true,  }]}>
                     <Input placeholder='expected_ctc'/>
                 </Form.Item>
               </Col>
               <Col span={12}>
                 <h5>{`${`wd`}`}</h5>
                 <Form.Item label='Current CTC'  name='c_ctc' rules={[{ required: true,  }]}>
                     <Input placeholder='current_ctc'/>
                 </Form.Item>
                  <Form.Item label='Year Of Experience'  name='exp' rules={[{ required: true,  }]}>
                    <Input placeholder='Experience'></Input>
                   </Form.Item>
               </Col>
               <Col span={24}>
                 <Form.Item style={{
                     float: 'right'
                 }}>
                     <button style={{
                         width: '130px',
                         backgroundColor: 'ActiveBorder',
                         border: 'none',
                         outline: 'none',
                         padding: '5px 10px', 
                     }} onClick={() => {
                         SendData()
                     }} >Submit</button>
                 </Form.Item>
               </Col>
           </Row>
           </Form>
        </Modal>
       </>
    )
}
export default FormComp