import '../form/form.css'
import "../../../src/header.css";
import ScrollToTop from "../../scrolltop";
import { Card, Col, FormLabel, Row } from 'react-bootstrap';
import { useState } from 'react';
import {  Grid,  } from '@mui/material';
import axios from 'axios';

const Form  = ({name}) => {
  
  const [ expStatus, setExpStatus ] = useState('fresher');
  const [ resume, setResume ] = useState();

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append('full_name', document.getElementById('username').value);
    formdata.append('mobile_no', document.getElementById('mobile').value);
    formdata.append('email', document.getElementById('mail').value);
    formdata.append('location', document.getElementById('location').value);
    formdata.append('highest_qualify', document.getElementById('qual').value);
    formdata.append('expected_ctc', document.getElementById('expected_ctc').value);
    formdata.append('night_shifts', document.getElementById('html').value);
    formdata.append('expected_take_home', document.getElementById('expected_take_home').value);
    formdata.append('resume', resume);
    if ( expStatus === 'exp' ) {
      formdata.append('current_take_home', document.getElementById('current_take_home').value);
      formdata.append('current_ctc', document.getElementById('current_ctc').value);
    }
    //api post data
    axios('http://216.48.180.195:7700/seltam/careers', {
      method: 'POST',
      data: formdata,
    }).then((resp) => {
      console.log(resp)
      if (resp.status === 200) {
        alert('successfully Uploaded')
        document.getElementById('username').value = '';
        document.getElementById('mobile').value = '';
        document.getElementById('mail').value='';
        document.getElementById('location').value='';
        document.getElementById('qual').value='';
        document.getElementById('expected_ctc').value='';
        document.getElementById('html').value='';
        document.getElementById('expected_take_home').value='';
        setResume()
        if ( expStatus === 'exp' ) {
          formdata.append('current_take_home', document.getElementById('current_take_home').value = '');
          formdata.append('current_ctc', document.getElementById('current_ctc').value = '');
        }
        window.location.reload();
      }
    })
  }

 return(
  <div>
   <ScrollToTop/>
   <div class="container-fluid career_img-apply-now text-center  d-flex justify-content-center flex-column">
      <h2 class="display-5 service-heading text-white mt-5">Apply Now</h2>
   </div>
     <Row>
       <Col> 
       <Card >
      {/* <form> */}
      <Grid container columns={16} className='form-container'>
          <Grid item xs={8}>
             <div class='' style={{padding: '40px'}}>
             <h6 class='text-center'>Apply Job</h6>
              <input type='text' placeholder="Full Name" name="full_name" required id='username'></input>
              <input type='text' placeholder="Contact" name="mobile_no" required id='mobile'></input>
              <input  type='email' placeholder="Mail" name="mail" required id='mail'></input>
              <input type='text' placeholder="Current Location" name="location" required id='location'></input>
              <input type='text' placeholder="Highest Qualification" name="qual" required id='qual'></input>
              <select required placeholder='fresher/Experience' onChange={(e) => {setExpStatus(e.target.value); console.log(expStatus)}}>
                <option value='fresher' >Fresher</option>
                <option value='exp'>Experience</option>
              </select>
              {
                expStatus === 'exp' ? 
              <input type='text' placeholder="Experience" name="experience" required></input>
              : null
              }
              {
                expStatus === 'exp' ? 
              <input type='text' placeholder="Current CTC" name="ctc" required></input>
              : null
              }
              <button
               class='submit'
               type="submit"
               value="Submit"
               onClick={()=> {
                handleSubmit()
               }}
               >Submit</button>
            </div>
          </Grid>
          <Grid item xs={8} >
          <div class='' style={{padding: '40px'}}>
          <input type='text' placeholder="Expected CTC" name="exp_ctc" style={{marginTop: '35px'}} required id='expected_ctc'></input>
             {
                expStatus === 'exp' ? 
            <input type='text' placeholder="Current Take Home" name="current_takehome" id='current_take_home' required></input>
              : null
              }
            <input type='text' placeholder="Expected Take Home" name="exp_takehome" id='expected_take_home' required ></input>
            {
                expStatus === 'exp' ? 
              <input type='text' placeholder="Notice Period" name="notice" required id='notice_period'></input>
              : null
              }
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
              <label style={{marginRight: '10px'}}>Night shift</label>
              <input type="radio" id="html" name="nigth_shifts" value="yes"/>
              <label for="nigth_shifts">yes</label>
              <input type="radio" id="html" name="nigth_shifts" value="no"/>
              <label for="nigth_shifts">no</label>
              </div>
              <div>
                <label>Uplaod Resume</label>
                <input style={{marginTop: '10px', marginBottom: '20px', marginLeft: '10px'}} type='file' name='resume'
                onChange={(e) => {
                  setResume(e.target.files[0])
                }}
                 />
              </div>
            </div>
          </Grid>
       </Grid>
      {/* </form> */}
       </Card>
       </Col>
     </Row>
  </div>
 )
}
export default Form

