import react from "react";
import "./header.css";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import KeyboardDoubleArrowLeftSharpIcon from "@mui/icons-material/KeyboardDoubleArrowLeftSharp";
// import banner_homepage from "./assest/seltam_img/banner_homepage.mp4";
// import SeltamHomepagebanner from "./assest/seltam_img/SeltamHomepagebanner.mp4";
import bannervideo_2 from "./assest/seltam_img/bannervideo_2.mp4";
// import Navbar from "./navbar";

function Banner() {
  return (
    <div>
      {/* banner starts */}
      <video className="video1" autoPlay muted loop playsInline poster="array">
        <source src={bannervideo_2} type="video/mp4"></source>
      </video>
      {/* navbar */}
      
      {/* navbar */}
      <div className="overlay"></div>
      <div class="text">
        <div>
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner d-flex align-items-center" style={{marginTop:"50px"}}>
              <div class="carousel-item active">
                {/* <img src="..." class="d-block w-100" alt="..."> */}
                <div class="d-flex justify-content-center">
                  <div>
                  <h1 class='text-white'>"A one-stop solution for all <br></br></h1>
                    <p class="display-6 text-white">your<b class="text-warning"> business needs"</b></p>
                  <p className="banner-text">
                    Create a software or a website design which is Professional,
                    Innovative, <br></br>Unique and makes you successful with our valued
                    services
                  </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="d-flex justify-content-center">
                  <div>
                  <h1 class='text-white'>We take Responsibility for your <br></br> <b class="text-warning">Business Growth</b></h1>
                  <p className="banner-text">
                    Hiring the right people, reducing risks,<br></br> focusing on
                    customer experience and boosting your buisness’s growth
                  </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="d-flex justify-content-start flex-column align-items-center">
                  <h1  class='text-white'>It&#39;s not about ideas..</h1>
                  <h1 class="display-6 text-white">It&#39;s making ideas <b class="text-warning">happen!</b></h1>
                  <p className="banner-text">A successful brand identity is one of the company&#39;s<br></br> most valuable assets</p>
                </div>
              </div>
            </div>
            {/* buttons to be fix */}
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <KeyboardDoubleArrowLeftSharpIcon />
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <KeyboardDoubleArrowRightSharpIcon /> 
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* banner ends */}
    </div>
  );
}

function Carousel() {
  return (
    <div>
      {/* carousel */}

      {/* carousel */}
    </div>
  );
}

export default Banner;
