import react from "react";
import "./header.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink } from "react-router-dom";


function Cta() {
  return (
    <div class="" id="CTA">
      <div class="bg-img-holder d-flex flex-column justify-content-center align-items-center">
        <div class="container ">
          <div class="row d-flex flex-column justify-content-center align-items-center">
            <div class="col-xl-8  mt-5">
              <div class="text-center">
                <h3 class="h2">
                <b class="bold-txt" style={{textTransform: "uppercase"}}> Let's Create Something Together</b>
                </h3>
                <Link style={{ textDecoration: "none" }} to="contact">
                    <a class="nav-link learn-more text-white"><button class="footer-orange-btn mt-3">
                 Start Project
                 </button></a>
                  </Link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cta;
