import react from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Webappdev from "./webappdev";
import Software from "./softwaredev";
import Mobileapp from "./mobileapp";
import Footer from "./footer.js";

function Development() {
    return(
        <div>
            <Webappdev></Webappdev>
            <Software />
            <Mobileapp />
           
        </div>
    )
}
export default Development;