/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import react, { useState } from "react";
import "./cantact.css";
import Footer_2 from "./footer_for_all.js";
import ScrollToTop from "./scrolltop";

// import Popup from "./model_of_contactpage.js"

function Contact() {


  return (
    <div class="">
      <ScrollToTop />
      <div class="align-items-center contact_img text-center  d-flex row justify-content-around flex-row">
        <div class="col-lg-4 mt-5 text-center container">
          <form class="bg-light text-center p-5">
            <div class="d-flex  jutify-content-center align-items-center mt-3">
              <img
                width="40px"
                src="https://img.icons8.com/ios-filled/50/000000/username.png"
              />
              <input
                type="email"
                name="YourEmail"
                size="40"
                class=" mx-4 c-input wpcf7-validates-as-required wpcf7-validates-as-email emailicon"
                aria-required="true"
                aria-invalid="false"
                placeholder="Name*"
              />
            </div>

            <div class="d-flex jutify-content-center  mt-3">
              <img
                width="40px"
                src="https://img.icons8.com/ios-filled/50/000000/email-open.png"
              />
              <input
                type="email"
                name="YourEmail"
                size="40"
                class=" mx-4 c-input wpcf7-validates-as-required wpcf7-validates-as-email emailicon"
                aria-required="true"
                aria-invalid="false"
                placeholder="E-mail*"
              />
            </div>

            <div class="d-flex jutify-content-center   mt-3">
              <img
                width="40px"
                src="https://img.icons8.com/ios-filled/50/000000/company.png"
              />
              <input
                type="email"
                name="YourEmail"
                size="40"
                class=" mx-4 c-input wpcf7-validates-as-required wpcf7-validates-as-email emailicon"
                aria-required="true"
                aria-invalid="false"
                placeholder="Company*"
              />
            </div>
            <div class="d-flex jutify-content-center mt-3">
              <img
                width="40px"
                src="https://img.icons8.com/ios-filled/50/000000/apple-phone.png"
              />
              <input
                type="email"
                name="YourEmail"
                size="40"
                class=" mx-4 c-input  wpcf7-validates-as-required wpcf7-validates-as-email emailicon"
                aria-required="true"
                aria-invalid="false"
                placeholder="Phone*"
              />
            </div>
            <div class="d-flex jutify-content-center  mt-3">
              <img
                width="40px"
                src="https://img.icons8.com/external-sbts2018-mixed-sbts2018/58/000000/external-comment-social-media-basic-1-sbts2018-mixed-sbts2018.png"
              />
              <input
                type="email"
                name="YourEmail"
                size="40"
                class=" mx-4 c-input  wpcf7-validates-as-required wpcf7-validates-as-email emailicon"
                aria-required="true"
                aria-invalid="false"
                placeholder="Leave Your Message*"
              />
            </div>
            <div class="mt-5">
              <button class="submit" class="c-button">
                Send Message
              </button>
            </div>
          </form>
        </div>

        <div class="container mt-5 text-light col-lg-6">
          <p>
            Reach Out And <h3 class="display-5">Say Hello!</h3>
          </p>
          <p>
            Let’s Chat Over A Brew! Coffee, Tea, Or Something Stronger!<br></br>
            Tell us about your project and goals, and we’ll set up a time to
            chat!
          </p>
          {/* <div class="row mt-4">
            <div class="col-md-6">
              <h2>USA</h2>
              <p>San Jose</p>
            </div>
            <div class="col-md-6">
              <h2>USA</h2>
              <p>New Yourk</p>
            </div>
          </div> */}
          <div class="container d-flex flex-column">
            <p>
              Email : <span class="c-p">hr@seltam.co.in</span>
            </p>
            <p>
              Mobile : <span class="c-p">044-35541247</span>
            </p>
          </div>
          {/* <hr class="text-lighter" /> */}
          {/* <div class="container d-flex justify-content-around mt-5 row">
            <div class="col-sm-6">
              <h3 class="text-light">Support Info</h3>
              <div>
                <p>
                  <span class="c-p">E-Mail :</span> supportus@gmail.com
                </p>
                <p>
                  <span class="c-p">Phone :</span> +91 97363636363
                </p>
              </div>
            </div>
            <div class="col-sm-6">
              <h3 class="text-light">We Are Hiring</h3>
              <div>
                <p>
                  We love to be around awesome people. Why don't you join us?
                </p>
                <p>
                  Send Your Resume To <span class="c-p">jobs@gmail.com</span>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div class="cup-btn">
        <p class="" data-bs-toggle="modal" data-bs-target="#myModal">
          <i class="fas fa-handshake"></i>
        </p>

        {/* modal division */}

        {/* <!-- The Modal --> */}
        <div class="modal fade" style={{ zIndex: "7000000" }} id="myModal">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              {/* <!-- Modal Header --> */}
              <div class="modal-header d-flex flex-column">
                <h2 class="modal-title">Let's Get Started</h2>
                <h6 class="mt-3">Let us help you grow your business online.</h6>
                <p class="mt-4">
                  Fill in the form below and we’ll contact you shortly
                </p>
                <hr></hr>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                <div class="container-fluid  row">
                  <div class="col-sm-6 p-2">
                    <form>
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          {/* <label>First Name</label> */}
                          <input
                            class="c-input"
                            placeholder="FirstName*"
                            type="input"
                          ></input>
                        </div>
                        <div class="d-flex flex-column">
                          {/* <label>First Name</label> */}
                          <input
                            class="c-input mx-3"
                            placeholder="LastName*"
                            type="input"
                          ></input>
                        </div>
                      </div>

                      <div class="d-flex flex-row mt-3">
                        <div class="d-flex flex-column">
                          {/* <label>First Name</label> */}
                          <input
                            class="c-input"
                            placeholder="E-Mail*"
                            type="input"
                          ></input>
                        </div>
                        <div class="d-flex flex-column">
                          {/* <label>First Name</label> */}
                          <input
                            class="c-input mx-3"
                            placeholder="Phone*"
                            type="input"
                          ></input>
                        </div>
                      </div>
                      <div>
                        <input class="c2-input mt-3" placeholder="Company*" />
                      </div>
                      <div class="mt-5 text-center">
                        <button
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "600",
                            padding: "7px",
                            width: "300px",
                            outline: "none",
                            border: "none",
                          }}
                        >
                          Submit Your Details
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* second one */}
                  <div class="col-sm-4 mx-5">
                    <p>What services would you like get a proposal for?</p>
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">Brand Strategy & Market Positioning</p>
                    </div>
                    {/* braek */}
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">Logo Design & Brand Identity</p>
                    </div>
                    {/* braek */}
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">Website Design & Development</p>
                    </div>
                    {/* braek */}
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">App Development</p>
                    </div>
                    {/* braek */}
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">Search Engine Optimization</p>
                    </div>
                    {/* braek */}
                    <div class="d-flex flex-row align-item-center">
                      <input class="form-check-input" type="checkbox"></input>
                      <p class="mx-3">Social Media Marketing</p>
                    </div>
                    {/* braek */}
                    <div>
                      <textarea
                        class="form-control"
                        placeholder="Project Details*"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
      </div>

      <div id="map" class="" style={{ overflowY: "hidden" }}>
        <div class="map">
          <div class="col-lg-12 map" id="map">
            <iframe
              class="map"
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d62209.74962394073!2d80.1743839324679!3d12.964853960142959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x3a525db717441115%3A0x273d45c6275d4daf!2sSeltam%20Technologies%20solutions%20pvt%20ltd!3m2!1d12.9648563!2d80.2094036!4m0!5e0!3m2!1sen!2sin!4v1639740913401!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Contact;
