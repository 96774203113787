import react from "react";
import "./about_us.css";
import Vision from "./assest/seltam_img/Vision.jpg";
import Footer from "./footer.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import Navbar from "./navbar";
import ScrollToTop from "./scrolltop";
import Mission from "./assest/seltam_img/Mission.jpg";

// import mision from "./assest/mision.jpg";
// import career_2 from "./assest/career_2.jpg";

function About() {
  return (
    <div class="" id="about-us">
            <ScrollToTop />
      <div class="about_container  container-fluid bg-danger text-center d-flex justify-content-center flex-column">
        <h2 className="smart-heading" class="display-2 text-white"></h2>
      </div>
      <div class="container my-5 text-center">
        <h3 class="fifth-h1-2 my-3">
          We Provide User Experiences With Deep Focus
        </h3>
        <div class="d-flex justify-content-center">
          <div class="border-down"></div>
        </div>
        <div class="container my-4">
          <p class="wwe-p">
            We specialize in Payroll services,
            Consulting, Application development, Web Design, Website
            development, Ecommerce Website, Logo Design, Custom Development, SEO
            and SMM.
          </p>
        </div>
      </div>
      <div class="container my-5" id="about-body">
        <div class="row">
          <div class="col-lg-5 images-of-about">
            <img src={Vision} class="img-fluid vision-img" width="550px"></img>
          </div>
          <div class="col-lg-7 my-5">
            <h3 class="below-h2-1">Vision</h3>
            <div class="mt-2">
              <div class="border-orange"></div>
            </div>
            <div>
              <p class="wwe-p my-4">
                We Believe In The Development Of Long-Term Relationships With
                Our Clients And Promise By Keeping The Quality And Timeliness
                Into Concern.To Be The Most Creative, Insightful, And Agile
                Talent Company, Committed To Bonding Vital Talent With Great
                Organizations Where, Together, We Thrive.
              </p>
              <div>
              <Link style={{ textDecoration: "none" }} to="/contact">
              <button class="about-btn"><a>Contact</a></button>
            </Link>
              </div>
            </div>
          </div>
          {/*  */}
          <div class="col-lg-5 my-4 images-of-about">
            <img src={Mission} class="img-fluid vision-img" width="550px"></img>
          </div>
          <div class="col-lg-7 my-5">
            <h3 class="below-h2-1">Mission</h3>
            <div class="mt-2">
              <div class="border-orange"></div>
            </div>
            <div>
              <p class="wwe-p my-4">
              To boost the business growth of our clients with extra
                  ordinary services that build value and unchanging proficient
                  advantage for you across the world. To deliver unique services
                  which process quality and customer satisfaction to add value
                  to your business.
              </p>
              <div>
              <Link style={{ textDecoration: "none" }} to="/cantact">
              <button class="about-btn"><a>Contact</a></button>
            </Link>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      {/*  */}
      <div class="end-flex container-fluid d-flex align-items-center">
        <p class="wwe-p-white border-flex p-5 mt-2">
        We are the experts in recruiting qualified, professional and skilled people in the IT & BPO sector. At Seltam, we believe the right job can transform a person's life and the right person can transform a business. We strive for your Business growth to place the right people in the right jobs; we invest heavily in equipping our staff with the skills to perform to the highest standards while developing their careers. Providing excellent customer service underpins our business and is an area we monitor closely in order to maintain our position as market leader.
        </p>
      </div>
    </div>
  );
}
export default About;
