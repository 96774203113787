/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { message } from 'antd';
import axios from 'axios';
import { Button } from 'bootstrap';
import { Modal } from 'bootstrap';
import react, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, ButtonToolbar, Card } from 'react-bootstrap';
import './career.css';
import ReadMoreReact from 'read-more-react';
import ScrollToTop from './scrolltop';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { jobData } from './jobdata/jobdata';
// import Modal from 'react-bootstrap/Modal'

function Career() {
	const navigate = useNavigate()
	const [ mapData, SetMapData ] = useState();
	const updates = useRef(0);
	const formData = new FormData();
	const [ resume, setResume ] = useState([]);
	const [ fname, setfname ] = useState('');
	const [ loc, setloc ] = useState('');
	const [ c_number, setc_number ] = useState('');
	const [ mail, setmail ] = useState('');
	const [ qual, setqual ] = useState('');
	const [ exp, setexp ] = useState('');
	const [ shift, setshift ] = useState('');
	const [ c_ctc, setc_ctc ] = useState('');
	const [ e_ctc, sete_ctc ] = useState('');

	const postData = (e) => {
		formData.append('full_name', fname);
		formData.append('mobile_no', c_number);
		formData.append('email', mail);
		formData.append('highest_qualify', qual);
		formData.append('location', loc);
		formData.append('experience', exp);
		formData.append('current_ctc', c_ctc);
		formData.append('expected_ctc', e_ctc);
		formData.append('night_shift', shift);
		formData.append('resume', resume);
		console.log(formData.fname);
		console.log('clicked');
		axios('http://192.168.29.111:9000/seltam/careers', {
			method: 'POST',
			data: formData
		}).then((resp) => {
			console.log(resp.data);
			if (resp.status == 200) {
				alert('Succesfully Uploaded');
			}
		});
	};

  const applyJob = () => {
			window.location.href = '/form'
  } 

   useEffect(() => {
	   jobData.map((items, index) => {
	       console.log(items.p_id)
	   })
   })
  
	return (
		<div class="">
			<ScrollToTop />
			<div class="container-fluid career_img text-center  d-flex justify-content-center flex-column">
				<h2 class="display-5 service-heading text-white mt-5">Careers</h2>
			</div>
			<h3 class = 'mx-4 mt-3'>Current openings</h3>
      <div class='d-flex my-5'>
		  
      {
        	jobData.map((item) => {
				return(
					<Card class='my-5 mx-5'  style={{ width: '25rem', marginLeft: '80px' }}>
					<Card.Img
						variant="top"
						src="https://www.roberthalf.com/sites/default/files/2021-04/shutterstock_412257712-2.jpg"
					/>
					<Card.Body>
						<Card.Title>{item.name}</Card.Title>
						<Card.Text>
						{<p>{item.loc}</p>}
							{<p class='mx-1'>{item.desc}</p>}{<Link to={`/applyJobs?p_id=${item.p_id}`}>
							<a>more</a>
								</Link>}
						</Card.Text>
						<button type="submit" class="btn-for-career" onClick={() => {
              applyJob()
            }}>
							Apply Now
						</button>
					</Card.Body>
				</Card>
				)
			})	
	  }
      </div>
		</div>
	);
}
export default Career;
