import react from "react";
import "./main.css";
import Payroll from "./payroll";
import Recruitment from "./recruitment";

function R_and_s() {
    return(
       <div>
           <Payroll></Payroll>
           <Recruitment></Recruitment>
       </div>
    )
}
export default R_and_s;