import react from "react";
import Digitalmarketing from "./digitalmarketing";
import "./service.css";
import Software from "./softwaredev";
import Webappdev from "./webappdev";
import Footer from "./footer.js";
import Payroll from "./payroll";
import Recruitment from "./recruitment";
import ScrollToTop from "./scrolltop";
import Mobileapp from "./mobileapp.js";


function Services() {
  return (
    <div>
       <ScrollToTop />
      <div class="service_img text-center d-flex justify-content-center flex-column">
        <h2 className="heading">
          Services
        </h2>
      </div>
      <div class="mt-5">
      <Webappdev />
      </div>
      <Software />
      <Mobileapp />
      <Digitalmarketing />
      <Payroll />
      <Recruitment />
    </div>
  );
}
export default Services;
