import react from "react";
import "./header.css";
import "./service.css";
import "./software.css";
import Softwaredevelopment from "./assest/seltam_img/Softwaredevelopment.jpg";
import e_com_2 from "./assest/seltam_img/e_com_2.jpg";
import crmsoftware from "./assest/seltam_img/crmsoftware.jpg";
import wordpressdevelopment1 from "./assest/seltam_img/wordpressdevelopment1.jpg";
import maintenance from "./assest/seltam_img/maintenance.jpg";
import appdevelopment from "./assest/seltam_img/appdevelopment.jpg";
import Footer from "./footer.js";
import ScrollToTop from "./scrolltop";
import emd_new from "./assest/seltam_img/emd_new.jpg";
import CRM_1 from "./assest/seltam_img/CRM_1.jpg";

function Software() {
  return (
    <div class="mb-5">
      <ScrollToTop />
      <div class=" software_img container-fluid text-center d-flex justify-content-center flex-column">
        <h2 class="display-5 service-heading text-white mt-5">
          Software Development
        </h2>
      </div>
      <div class="container-fluid mt-5 text-center">
        <p className="fifth-h1-2">Building Innovative Digital Applications</p>
        <div class="d-flex justify-content-center">
          <div class="border-down"></div>
        </div>
        <p class="wwe-p my-3">
          We transform your business by building innovative digital
          applications. We develop and promote advanced information technologies
          for multi-user operations.<br></br> Seltam's business philosophy is to
          assure the highest quality product, total client satisfaction, timely
          delivery of solutions<br></br> and the best quality/price ratio found
          in the industry.
        </p>
      </div>
      <div class="container-fluid my-5">
        <div class="row">
          {/*  */}

          <div class="col-lg-6 right-and-left">
            <h3 class="below-h2-1 mt-3 px-5 text-center">Ecommerce Websites</h3>
            <div>
              <p class="wwe-p  pt-2 pt-3 px-5 text-center">
                Ecommerce website allows people to buy and sell physical goods,
                services, and digital products over the internet rather than at
                a brick-and-mortar location. Seltam builds reliable Ecommerce
                websites and customization services to build solutions that
                match the client’s requirements and helps in staying Digitally
                Competitive.
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <div class="software-border"></div>
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="img-software-1 right-and-left"></div>
          </div>
          {/*  */}
          {/*  */}

          <div class="col-lg-6 right-and-left mt-4">
            <h3 class="below-h2-1 mt-3 px-5 text-center">CRM Software</h3>
            <div>
              <p class="wwe-p  pt-2 pt-3 px-5 text-center">
                CRM is a platform that connects different departments by
                providing a complete picture of all customer interactions,
                keeping track of your sales, organizing and prioritizing your
                opportunities, and facilitating collaboration between various
                teams. Every user has easy, direct access to the real-time
                business data they need. A unique approach to automate and
                upgrade your business’s interaction with existing as well as new
                customers.
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <div class="software-border"></div>
            </div>
          </div>
          <div class="col-lg-6 mt-4">
            <div class="img-software-2 right-and-left"></div>
          </div>
          {/*  */}
          {/*  */}

          <div class="col-lg-6 right-and-left mt-4">
            <h3 class="below-h2-1 mt-3 px-5 text-center">
              Embedded Applications
            </h3>
            <div>
              <p class="wwe-p  pt-2 pt-3  text-center">
              An embedded application is software that is placed permanently inside some kind of device to perform a very specific set of functions. The program instructions for embedded systems are called firmware, or embedded software, and are stored in read-only memory, or flash memory chips.
              </p>
              <p class="wwe-p    text-center">
              Embedded systems traditionally haven’t been the easiest when it comes to user interaction. With the explosion of IoT devices and their ubiquity in every-day life, users are demanding better, more user- friendly interfaces. This will be provided as a web interface or application hosted by the device itself.
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <div class="software-border"></div>
            </div>
          </div>
          <div class="col-lg-6 mt-4">
            <div class="img-software-3 right-and-left"></div>
          </div>
          {/*  */}
          {/*  */}

          <div class="col-lg-6 right-and-left mt-4">
            <h3 class="below-h2-1 mt-3 px-5 text-center">Maintenance</h3>
            <div>
              <p class="wwe-p  pt-2 pt-3 px-5 text-center">
              Maintenance are not just about expanding and supporting IT applications, it is to add value, enhance business results and most importantly, help you particularly serve customers in this digital era. Maintenance at Seltam includes Regular Updates, Backups, Security, Monitoring, Analytics, Fresh content, Support, etc.
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <div class="software-border"></div>
            </div>
          </div>
          <div class="col-lg-6 mt-4">
            <div class="img-software-4 right-and-left"></div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
}
export default Software;
