import react, { useState } from "react";
import "./header.css";
import CallIcon from "@mui/icons-material/Call";

import DehazeIcon from "@mui/icons-material/Dehaze";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import stnewlogo from "./assest/seltam_img/stnewlogo.svg";
import Dropdown from "./dropdown";

function Navbar() {
  var [dropdown, showdropdown] = useState(false);
  var nav_color = "#180f27";

  var change_nav = () => {
    if (window.scrollY >= 180) {
      document.getElementById("navbar").style.backgroundColor = nav_color;
    } else {
      document.getElementById("navbar").style.backgroundColor = "transparent";
    }
  };

  window.addEventListener("scroll", change_nav);

  return (
    <nav
      id="navbar"
      class="navbar navbar-expand-lg container-fluid  ms-auto d-flex flex-row justify-content-between d-xl-flex  justify-content-xl-between"
    >
      <p class="mt-3 text-light seltam-brand">
        <Link style={{ textDecoration: "none" }} to="/">
        <a class="text-light d-flex align-items-center text-none">
          <img
            width="35px"
            class="mx-2"
            src={stnewlogo}
            alt="seltam_logo"
          ></img>
          <bold class="seltam-text d-none d-xl-block">Seltam</bold>
          <bold class="d-none d-none d-xl-block mx-1">Technologies</bold>
        </a>
        </Link>
      </p>

      <button
        class="navbar-toggler mx-3 text-light"
        data-bs-toggle="collapse"
        data-bs-target="#show"
      >
        <DehazeIcon></DehazeIcon>
      </button>

      <div class="collapse navbar-collapse" id="show">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item mx-4">
            <Link style={{ textDecoration: "none" }} to="/">
              <a class="nav-link">Home</a>
            </Link>
          </li>
          <li
            style={{ position: "relative" }}
            class="nav-item dropdown mx-4"
            onMouseEnter={() => {
              showdropdown(true);
            }}
            onMouseLeave={() => {
              showdropdown(false);
            }}
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDarkDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </a>
            <div style={{ position: "absolute" }}>
              {dropdown === true && <Dropdown />}
            </div>
          </li>
          <li class="nav-item mx-4">
            <Link style={{ textDecoration: "none" }} to="about">
              <a class="nav-link">About Us</a>
            </Link>
          </li>
          <li class="nav-item mx-4">
            <Link style={{ textDecoration: "none" }} to="career">
              <a class="nav-link">Careers</a>
            </Link>
          </li>
          <li class="nav-item mx-4">
            <div class="text-light">
              <Link style={{ textDecoration: "none" }} to="contact">
                <a class="nav-link">Contact Us</a>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
export default Navbar;
