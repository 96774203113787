import react from "react";
import "./payroll.css";
import "./header.css";
import Footer from "./footer.js";
import ScrollToTop from "./scrolltop";

function Payroll() {
  return (
    <div class="" className="services">
      <ScrollToTop />
      <div class="container-fluid payroll_img text-center  d-flex justify-content-center flex-column">
        <h2 class="display-5 service-heading text-white mt-5">
          Payroll Outsourcing Services
        </h2>
      </div>
      <div class="container-fluid mt-5 text-center">
        <h4 className="rs-h4">Seltam Payroll Outsourcing Company</h4>
      </div>
      {/* <hr class="container"></hr> */}
      <div class="d-flex justify-content-center mb-5 mt-4">
        <div class="border-recruit"></div>
      </div>
      <div class="container d-flex-justify-content-center">
        <p class="wwe-p">
          Our payroll outsourcing services offer fast flexible and effective
          payroll with guaranteed on-time payments to your independent contract
          workers. Seltam provides reliable payroll processing services to serve
          companies in getting rid of the administrative pressure about payroll.
        </p>
        <p class="wwe-p">
          Our sole motto is to offer you the scope and time to invest in your
          core business operations. Let us handle your monthly payroll. Think
          about how much money you can make investing the same efforts on
          business generation while we make calculations about your employees’
          salary.
        </p>
      </div>
      <div class="container mt-5">
        <div class="px-5  flex">
          <h6 class="rs-h6 text-center text-warning mt-4">
            Why Choose Seltam For Payroll Outsourcing Services ?
          </h6>
          <p class="wwe-p-white mt-4">
            You can be assured that our goal is to be more than being a vendor.
            We aim to be your partner in growth, proactively helping you meet
            rapidly changing business realities and legislation. Many companies
            find it difficult to entrust sensitive business processes to an
            outside organization. But as atrusted payroll service provider, we
            work to develop relationships at both the operational and service
            levels, so you can turn to us at any time with whatever issue you
            face. To maintain the utmost level of assurance for our clientele
            with regard to data security, we have robust protection systems and
            security practices in place.
          </p>
        </div>
      </div>
      <div class="container">
        {/* <div class="p-2">
          <h6 class="rs-h6 text-center mt-4">
            Increased Legislative Compliance
          </h6>
          <p class="paragraph-j">
            Seltam managing end-to-end payroll services, handling tax and
            statutory compliances, labor compliance to protect the company’s
            reputation.
          </p>
        </div> */}
      </div>
      <div class="container-fluid d-flex-justify-content-between">
        <div class="container-fluid  row text-center my-5">
          <div class="d-flex  justify-content-center">
            <div class="col-md-4  mx-2 my-3 d-flex justify-content-center align-items-center flex-column payroll-grid">
              <h6 className="rs-h-t-small">Complete End-to-End Services</h6>
              <p className="paragraph-wd">
                From monthly pays slips to full and final settlement, Seltam
                delivers all the payroll services related to the payroll
                {/* administration. */}
              </p>
            </div>
            <div class="col-md-4  mx-2 my-3 d-flex justify-content-center align-items-center flex-column payroll-grid">
              <h6 className="rs-h-t-small">Flexible System</h6>
              <p className="paragraph-wd">
                Our payroll system gives you numerous flexible options like
                computing variable pay, complex pay structures, multiple salary
                revisions, and support most bank formats.
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="col-md-4 payroll-grid d-flex justify-content-center align-items-center flex-column my-3 mx-2">
              <h6 className="rs-h-t-small">Outsourcing is Being Smart</h6>
              <p className="paragraph-wd">
                Outsourcing your payroll to a robust and reliable third-party
                payroll service provider like Seltam gives you peace of mind and
                allows you to focus on your core business activities.
              </p>
            </div>
            <div class="col-md-4 payroll-grid d-flex justify-content-center align-items-center flex-column my-3 mx-2">
              <h6 className="rs-h-t-small">Data Security</h6>
              <p className="paragraph-wd">
                Our up-to-date technology means that your sensitive and
                confidential data and information is in safe hands.
              </p>
            </div>
            <div class="col-md-4 payroll-grid d-flex justify-content-center align-items-center flex-column my-3 mx-2">
              <h6 className="rs-h-t-small">Modern Technology</h6>
              <p className="paragraph-wd">
                Our payroll data management system utilizes the most modern
                technology to give you a superior, fully automated payroll
                system experience with 100% accuracy and error-free payroll
                process.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default Payroll;
