import react from "react";
import "./header.css";
import stnewlogo from "./assest/seltam_img/stnewlogo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

function Footer() {
  return (
    <div class="p-5 d-flex align-items-center" id="footer">
      <div class="container d-flex align-items-center">
        <div class="row d-flex justify-content-evenly">
          <div class="col-md-4 mb-4 mb-lg-0">
            <h4 class="h4">It's not about ideas.. It's making ideas happen!</h4>
            <div>
              <p class="footer-para">
                Create a software or a website design which is Professional,
                Innovative, Unique and makes you successful with our valued
                services.
              </p>
            </div>
            <div class="social-footer-btn mt-4">
              <button class="footer-fb-btn">Facebook</button>
              <button class="footer-twitter-btn mx-2">Twitter</button>
            </div>
          </div>
          <div class="col-md-4  mb-4 mb-lg-0">
            <div class="container">
              <div class="row d-flex justify-content-center">
                <div class="col-6">
                  <h3 class="h3">LINK</h3>
                  <div class="mt-3">
                    <Link style={{ textDecoration: "none" }} to="/service">
                      <p class="footer-p-link">Services</p>
                    </Link>
                    <Link style={{ textDecoration: "none" }} to="/about">
                      <p class="footer-p-link">About Us</p>
                    </Link>
                    <Link style={{ textDecoration: "none" }} to="/career">
                      <p class="footer-p-link">Careers</p>
                    </Link>
                    <Link style={{ textDecoration: "none" }} to="/contact">
                      <p class="footer-p-link">Contact Us</p>
                    </Link>
                  </div>
                </div>
                <div class="col-6">
                  <h3 class="h3">SERVICES</h3>
                  <div class="mt-3">
                    <p class="footer-p-link">Development</p>
                    {/* <p class="footer-p-link">Recruitment and Staffing</p> */}
                    <p class="footer-p-link">Digital Marketing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-3 mb-4">
            <div class="container w-100 d-flex justify-content-center">
              <div class="row">
                <div class="col-12">
                  {" "}
                  <Link style={{ textDecoration: "none" }} to="/contact">
                  <button class="footer-orange-btn">Stay In Touch</button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 mx-2">
            <p class="footer-para">
            copyright @ 2021 Seltam Technologies.All rights are reserved.
              {/* <b class="mx-4 footer-p-link">Tearms of Service</b>{" "}
              <b class="mx-1 footer-p-link">Privacy Policy</b> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
