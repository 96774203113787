import React from "react";
import "./recruitment.css";
import Footer from "./footer.js";
import ScrollToTop from "./scrolltop";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import recruitment_video from "./assest/seltam_img/recruitment_video.mp4";
import { height } from "@mui/system";

function Recruitment() {
  return (
    <div>
      <ScrollToTop />
      <div class="container-fluid recruit_img text-center  d-flex justify-content-center flex-column">
        <h2 class="display-5 service-heading text-white mt-5">
          Recruitment Services
        </h2>
      </div>
      <div class="container mt-5">
        
          <h3 class="rs-h4 text-center"> Talent Acquisition Services</h3>
          {/* <h3 class="rs-h6 text-center text-secondary">
          Talent Acquisition Services
          </h3> */}
          <div class="d-flex justify-content-center mb-5 mt-4">
            <div class="border-recruit"></div>
          </div>
          <h3 class="wwe-h3   mt-5">Sourcing and Recruiting experts in BPO</h3>
          <p class="wwe-p mt-3">
            Our talent acquisition specialists support you at every stage of the
            recruitment process. They identify experienced candidates according
            to the clients’ needs, no matter how niche the role is.
          </p>
          <p class="wwe-p">
            It is this level of service and expertise that allows us to offer
            the most resourceful, workforce solutions in the industry. It has
            also enabled us <br></br> to work with the world’s largest employers
            to staff flagship projects globally.
          </p>
      
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="wwe-h3 my-4">Our Recruitment Process</h3>
            <p class="my-4 wwe-p">
              Seltam is a seasoned staffing and manpower recruitment services
              company who can find the right candidate for the right job in
              terms of fitment. This helps our clients to reduce the performance
              turnaround time of the candidate. It also helps organizations to
              spend lesser time in waiting for the candidate to start producing
              results.All these factors affect delivery, productivity, and the
              bottom line directly.
            </p>
          </div>
          <div class="container d-flex justify-content-center">
            <div class="row my-4 d-flex justify-content-center">
              <div class="col-lg-5">
                <video
                  style={{ height: "250px" }}
                  muted
                  autoPlay
                  loop
                  datatype="array"
                >
                  <source src={recruitment_video} type="video/mp4"></source>
                </video>
              </div>
              <div class="col-lg-4 mx-4">
                <p class="wwe-p">
                  {" "}
                  With a large database of candidates, we can handle all your
                  recruitment process outsourcing with utmost sincerity,
                  dedication and commitment. We are a value adding recruitment
                  service agency in India which is why we are in touch with our
                  candidates always and they also come back to us if they need
                  any placement help.
                </p>
                <Link style={{ textDecoration: "none" }} to="/contact">
                  <button class="recruit-btn">Contact</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="container px-4">
          <h3 class="wwe-h3 text-center mt-5">
            Talent Acquisition: Contract, Permanent and Mass Hire
          </h3>
        </div>
        <div class="col container my-5">
          <div class="recruit-grid mt-2  p-4">
            <h4 class="rs-h-t-small text-center">Contract Talent</h4>
            <p class="wwe-p-c">
              Hire skilled professionals on a temporary basis for short- or
              long-term assignments.
            </p>
          </div>
          <div class="recruit-grid mt-4 p-4">
            <h4 class="rs-h-t-small text-center mt-2">Permanent Placement</h4>
            <p class="wwe-p-c">
              Add full-time employees — early- to mid-career or executive level.
            </p>
          </div>
          <div class="recruit-grid mt-4 p-4">
            <h4 class="rs-h-t-small text-center mt-2">Managed Solutions</h4>
            <p class="wwe-p-c">
              Access deep consulting expertise or specialized project teams to
              solve business challenges.
            </p>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default Recruitment;
