import react from "react";
import Webappdev from "./webappdev";
import Software from "./softwaredev.js";
import "./mobileapp.css";
import mobile_hybrid from "./assest/seltam_img/mobile_hybrid.png";
import icon_native from "./assest/seltam_img/icon_native.png";
import native from "./assest/seltam_img/native.png";
import hybrid from "./assest/seltam_img/hybrid.png";
// import Footer from "./footer.js";
import Footer from "./footer.js";
import ScrollToTop from "./scrolltop";

function MobileApp() {
  return (
    <div>
      <ScrollToTop />
      <div class="container-fluid mob_img text-center  d-flex justify-content-center flex-column">
        <h2  class="display-5 service-heading text-white mt-5">
          Mobile Application Development
        </h2>
      </div>
      <div class="container-fluid mt-5 text-center">
        <p className="fifth-h1-2">
          Rich Functionality &amp; Interactive Experiences
        </p>
        {/* <h4 className="wd-h4">Software Development</h4> */}
        <p class="wwe-p">
          Mobile is not only becoming the new digital hub, but also is rapidly
          growing as the bridge to the physical world. Mobile app will transform
          your entire business  <br></br> with real-time and live accessibility of data. We
          build outstanding mobile apps for all industries, startups and
          enterprises.<br></br> Mobile app helps to make your business more profitable.
        </p>
      </div>
      <div class="mt-4 image-banner text-center">
        <div
          style={{ height: "80vh" }}
          class="container d-flex justify-content-end align-items-center"
        >
          <p class="text-white wwe-p " style={{ width: "230px" }}>
            <h3 class="text-info">
            Client-Centric Innovative Applications
            </h3>
            <p class="mt-3 text-white paragraph-j1">
            Delivering digital transformation with mobile apps that enable to do business – anywhere, anytime. Mobile apps are among the best forms of performance support enabling economic growth in the technology sector.
            </p>
          </p>
        </div>
      </div>
      <div
        style={{ height: "fit-content" }}
        class="row  bg-dark p-4 d-flex justify-content-between align-item-center"
      >
        <div class="col-sm">
          <div class="container" style={{ marginLeft: "50px" }}>
            <img src={hybrid} class="mt-1"></img>

            <h5 class="text-white mt-4">Native Application</h5>
            <p class="native-text mt-4">
              A native application is a software program that is built for use
              on a particular device and its OS. It has the ability to use
              device-specific hardware and software.
              <ul class="mt-5">
                <p class="  d-flex">
                  <p>
                    <i class="fas fa-check-square  text-white"></i>
                  </p>
                  <p class="mx-2  native-text">
                    Native Apps offer an excellent secure environment
                  </p>
                </p>
                <p class=" d-flex">
                  <p>
                    <i class="fas fa-check-square text-white"></i>
                  </p>
                  <p class="mx-2 native-text">
                    Best-in-class user experience with hi-end performance
                  </p>
                </p>
                <p class=" native-text d-flex">
                  <p>
                    <i class="fas fa-check-square  text-white"></i>
                  </p>
                  <p class="mx-2 native-text">
                    Native Apps are more Interactive and Intuitive
                  </p>
                </p>
              </ul>
            </p>
          </div>
        </div>
        <div style={{ marginLeft: "-0px" }} class="col-sm">
          <img width="400px" src={mobile_hybrid} />
        </div>
        <div class="col-sm mt-1">
          <div class="container" style={{ marginLeft: "-20px" }}>
            <img src={native}></img>

            <h5 class="text-white mt-4">Hybrid Application</h5>
            <p class="native-text mt-4">
              Hybrid app development is the creation of a single app that can
              run on multiple operating systems including Windows, Android and
              iOS.
              <ul class="mt-5">
                <p class="  d-flex">
                  <p>
                    <i class="fas fa-check-square  text-white"></i>
                  </p>
                  <p class="mx-2  native-text">
                    Cost-effective with ease of development
                  </p>
                </p>
                <p class=" d-flex">
                  <p>
                    <i class="fas fa-check-square text-white"></i>
                  </p>
                  <p class="mx-2 native-text">
                    Faster time to market with compatible performance
                  </p>
                </p>
                <p class=" native-text d-flex">
                  <p>
                    <i class="fas fa-check-square  text-white"></i>
                  </p>
                  <p class="mx-2 native-text">
                    Easier to scale on another platform
                  </p>
                </p>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div class="">{/* <Footer /> */}</div>
    </div>
  );
}
export default MobileApp;

// <p class="paragraph-j container">
//   Mobile is not only becoming the new digital hub, but also is rapidly
//   growing as the bridge to the physical world. Mobile app will transform
//   your entire business with real-time and live accessibility of data. We
//   build outstanding mobile apps for all industries, startups and
//   enterprises. Mobile app helps to make your business more profitable.
// </p>
