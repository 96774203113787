import react from "react";
import "./header.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink } from "react-router-dom";

function Ourservice() {
  return (
    <div>
      <div class="container-fluid our-service d-flex justify-content-center">
        <div class="d-flex flex-column">
          <h1 className="fifth-h1-2 mt-4">Our Services</h1>
          <p class="wwe-p">We provide a wide range of creative services</p>
          <div class="container d-flex align-items-center justify-content-center p-4 row ">
            <div class="col-lg-5 mt-3 border-bg d-flex flex-column">
              <div>
                <img
                  class="mt-2"
                  src="https://img.icons8.com/external-wanicon-lineal-wanicon/54/000000/external-computer-free-time-wanicon-lineal-wanicon.png"
                />
              </div>
              <h3 class="wwe-h3 mt-2">Development</h3>
              <div class="mt-1">
                <p class="wwe-p">Static and Dynamic website</p>
                <p class="wwe-p">E-commerce website</p>
                <p class="wwe-p">ERP Software</p>
                <p class="wwe-p">Android App</p>
                <p class="wwe-p">IOS App</p>
                <button className="Lm-btn">
                  <Link style={{ textDecoration: "none" }} to="deve">
                    <a class="nav-link learn-more">Learn More</a>
                  </Link>
                </button>
              </div>
            </div>
            {/* <div class="col-lg-5 mx-4 mt-3  border-bg">
              <div>
                <img
                  class="mt-2"
                  src="https://img.icons8.com/external-others-phat-plus/54/000000/external-career-business-cooperation-outline-others-phat-plus-21.png"
                />
              </div>
              <h3 class="wwe-h3 mt-2">Recruitment and Staffing</h3>
              <div class="mt-3">
                <p class="wwe-p">Payroll Outsourcing Services</p>
                <p class="wwe-p">Talent Acquisition Services</p>
                <p class="wwe-p">Contract Talent</p>
                <p class="wwe-p">Permanent Placement</p>
                <p class="wwe-p">Managed Solutions</p>
                <button className="Lm-btn">
                  <Link style={{ textDecoration: "none" }} to="R_and_s">
                    <a class="nav-link learn-more">Learn More</a>
                  </Link>
                </button>
              </div>
            </div> */}
            <div class="col-lg-5 mt-3  border-bg">
              <div>
                <img
                  class="mt-2"
                  src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/54/000000/external-digital-marketing-web-flatart-icons-outline-flatarticons.png"
                />
              </div>
              <h3 class="wwe-h3 mt-2">Digital Marketing</h3>
              <div class="mt-3">
                <p class="wwe-p">Social-Media Marketing</p>
                <p class="wwe-p">SEO &amp; SEM</p>
                <p class="wwe-p">Email Marketing</p>
                <p class="wwe-p">Influencer Marketing</p>
                <p class="wwe-p">Mobile Marketing</p>
                <button className="Lm-btn">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="digitalmarketing"
                  >
                    <a class="nav-link learn-more">Learn More</a>
                  </Link>
                </button>
              </div>
            </div>
            <div class="col-lg-5 mx-4 mt-3  border-bg ">
              <div>
                <img
                  class="mt-2"
                  src="https://img.icons8.com/external-others-phat-plus/54/000000/external-courses-online-courses-outline-others-phat-plus-26.png"
                />
              </div>
              <h3 class="wwe-h3 mt-2">Training & Placement</h3>
              <div class="mt-3">
                <p class="wwe-p">Core java &amp; Javascript</p>
                <p class="wwe-p">Angular (Advanced)</p>
                <p class="wwe-p">Core Python</p>
                <p class="wwe-p">Node js, TypeScript, MongoDB</p>
                <p class="wwe-p">C And C++</p>
                <button className="Lm-btn">
                  <Link style={{ textDecoration: "none" }} to="deve">
                    <a class="nav-link learn-more">Learn More</a>
                  </Link>
                </button>
              </div>
            </div>
            {/* <div class="text-center mt-5">
              <h1 class="display-7  text-dark">Let's Create Something Together</h1>
              <button class="sp-btn" style={{ width: "fit-content" }}>
                <Link style={{ textDecoration: "none" }} to="contact">
                  <a class="nav-link learn-more">Start a Project</a>
                </Link>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Ourservice;

// <div>
// <div id="our_service" class="container-fluid p-3">
//   <div class="text-center mt-5">
//     <h1 className="fifth-h1-1 text-white">Our Services</h1>
//     {/* <h1 className="fifth-h1-2">for Small Businesses.</h1> */}
//   </div>
//   <div class=" mt-3 d-flex justify-content-between  row">
//     <div id="three-service-tech" class="col-md-3 mt-4">
//       <img
//         class="mt-2"
//         src="https://img.icons8.com/external-wanicon-lineal-wanicon/54/000000/external-computer-free-time-wanicon-lineal-wanicon.png"
//       />
//       <h3 className="fifth-h3 mt-4">Web & App Development</h3>
//       <div class="d-flex m-4 flex-column align-items-center justify-content-center">
//         <p class="paragraph-srv">Static and Dynamic website</p>
//         <p class="paragraph-srv">E-commerce website</p>
//         <p class="paragraph-srv">ERP Software</p>
//         <p class="paragraph-srv">Android App</p>
//         <p class="paragraph-srv">IOS App</p>
//         <button className="Lm-btn">
//           <Link style={{ textDecoration: "none" }} to="deve">
//             <a class="nav-link learn-more">Learn More</a>
//           </Link>
//         </button>
//       </div>
//     </div>
//     <div id="three-service-tech" class="col-md-3 mt-4">
//       <img
//         class="mt-2"
//         src="https://img.icons8.com/external-others-phat-plus/54/000000/external-career-business-cooperation-outline-others-phat-plus-21.png"
//       />{" "}
//       <h3 className="fifth-h3 mt-4">Recruitment and Staffing</h3>
//       <div class="mt-4">
//         <div class="d-flex flex-column align-items-center justify-content-center">
//           <p class="paragraph-srv">Payroll Outsourcing Services</p>
//           <p class="paragraph-srv">Talent Acquisition Services</p>
//           <p class="paragraph-srv">Contract Talent</p>
//           <p class="paragraph-srv">Permanent Placement</p>
//           <p class="paragraph-srv">Managed Solutions</p>
//           <button className="Lm-btn">
//             <Link style={{ textDecoration: "none" }} to="R_and_s">
//               <a class="nav-link learn-more">Learn More</a>
//             </Link>
//           </button>
//         </div>
//       </div>
//     </div>
//     <div id="three-service-tech" class="col-md-3 mt-4">
//       <img
//         class="mt-2"
//         src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/54/000000/external-digital-marketing-web-flatart-icons-outline-flatarticons.png"
//       />{" "}
//       <h3 className="fifth-h3 mt-4">Digital Marketing platform</h3>
//       <div class="mt-4">
//         <div class="d-flex flex-column align-items-center justify-content-center">
//           <p class="paragraph-srv">Social-Media Marketing</p>
//           <p class="paragraph-srv">SEO &amp; SEM</p>
//           <p class="paragraph-srv">Email Marketing</p>
//           <p class="paragraph-srv">Influencer Marketing</p>
//           <p class="paragraph-srv">Mobile Marketing</p>
//           <button className="Lm-btn">
//             <Link style={{ textDecoration: "none" }} to="digitalmarketing">
//               <a class="nav-link learn-more">Learn More</a>
//             </Link>
//           </button>
//         </div>
//       </div>
//     </div>
//     <div id="three-service-tech" class="col-md-3 mt-4 ">
//       <img
//         class="mt-2"
//         src="https://img.icons8.com/external-others-phat-plus/54/000000/external-courses-online-courses-outline-others-phat-plus-26.png"
//       />{" "}
//       <h3 className="fifth-h3 mt-4">Technical Courses</h3>
//       <div class="mt-4">
//         <div class="d-flex flex-column align-items-center justify-content-center">
//           <p class="paragraph-srv">Core java &amp; Javascript</p>
//           <p class="paragraph-srv">Angular (Advanced)</p>
//           <p class="paragraph-srv">Core Python</p>
//           <p class="paragraph-srv">Node js, TypeScript, MongoDB</p>
//           <p class="paragraph-srv">C And C++</p>
//           <button className="Lm-btn">
//             <Link style={{ textDecoration: "none" }} to="deve">
//               <a class="nav-link learn-more">Learn More</a>
//             </Link>
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// </div>
