import { useState } from "react";
import "./header.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import "./about_us.css";
import "./dropdown.css";

function Dropdown() {
  // var [dropdown_1, func_1] = useState(false);
  // var [dropdown_2, func_2] = useState(false);
  
  // var show_dropdown = () => {
    var dropdown_no_1 =  document.getElementById("development");
    var dropdown_no_2 =  document.getElementById("requirment");

    var showdropdown_1  = () => {
      document.getElementById("dropdown_1").style.display = "block";
      document.getElementById("dropdown_2").style.display = "none";
    }
    var showdropdown_2 = () => {
      document.getElementById("dropdown_2").style.display = "block";
      document.getElementById("dropdown_1").style.display = "none";
    }
    var close_All = () => {
      document.getElementById("dropdown_2").style.display = "none";
    }

    

  // }

  return (
    <div
      class="dropdown_bg"
      style={{
      width:"200px"
      }}
    >
      <div class="d-flex flex-column mt-2 justify-content-center dropdown-link p-4 ">
        <p
          
          style={{ position: "relative" }}
          class="dr-a d-flex justify-content-between"
          id="development" 
          onMouseEnter={showdropdown_1}
        >
          <p class="dr-hover">Development</p>
          <i class="fas fa-angle-right "></i>
        </p>
{/* 
        <p
          
          s
          class="dr-a d-flex d-flex justify-content-between"
          style={{ position: "relative" }}
          id="requirment"
          onMouseEnter={showdropdown_2}
        
         
        >
          <p class="dr-hover">Recruitment and Staffing</p>
          <i class="fas fa-angle-right "></i>
        </p> */}

        <p class="dr-a dr-hover" onMouseEnter={close_All}>
        <Link class="dr-a dr-hover" style={{ textDecoration: "none" }} to="digitalmarketing">
            Digital Marketing
          </Link>
        </p>
         <Dropdownlink1 />
         <Dropdownlink2 />
      </div>
    </div>
  );
}

function Dropdownlink1() {
  return (
    <div id = "dropdown_1"
      style={{
        position: "absolute",
        top: "30px",
        left: "198px",
        width: "fit-content",
      }}
    >
      <div class="dropdown_bg p-4">
        <p class="dr-hover">
          <Link class="dr-a dr-hover"  style={{ textDecoration: "none" }} to="webapp">
            Web Design
          </Link>
        </p>
        <p class="dr-hover">
          <Link class="dr-a dr-hover" style={{ textDecoration: "none" }} to="software">
            Software Development
          </Link>
        </p>
        <p class="dr-hover">
          <Link class="dr-a dr-hover " style={{ textDecoration: "none" }} to="mobileapp">
            App Development
          </Link>
        </p>
      </div>
    </div>
  );
}
function Dropdownlink2() {
  return (
    <div class="" id = "dropdown_2"
      style={{
        position: "absolute",
        top: "80px",
        left: "198px",
        width: "fit-content",
      }}
    >
      <div class="dropdown_bg p-4">
        <p class="dr-hover">
          <Link class="dr-a dr-hover" style={{ textDecoration: "none" }} to="payroll">
            Payroll Outsourcing
          </Link>
        </p>
        <p class="dr-hover">
          <Link class="dr-a dr-hover" style={{ textDecoration: "none" }} to="recruit">
            Recruitment Services
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Dropdown;

//  <div class="dropdown-box">
// <ul class="d-flex flex-column justify-content-start">
// <li>Development</li>
// <li>Software Development</li>
// <li>Digital Marketing</li>
// </ul>
// </div>

// <div
// class="d-flex dropdown_bg"
// style={{
//   position: "absolute",
//   transition: ".3s all",
//   top: "40px",
//   right: "0px",
//   width: "300px",
//   borderRadius: "2px",
//   padding: "16px",
// }}
// >
// {/*  */}

// <div class="x mt-2" style={{ transition: "4s all" }}>
//   <p class="">
//     <a
//       class="dr-a navbar-toggle d-flex justify-content-between"
//       data-bs-toggle="collapse"
//       data-bs-target="#show1"
//     >
//       Development
//       <i class="fas fa-angle-down mt-1"></i>
//     </a>
//     <div class="collapse " id="show1">
//       <ul class="navbar-nav d-flex flex-column">
//         <li class="nav-item">
//           <Link
//             to="webapp"
//             style={{ textDecoration: "none" }}
//             class="child-link"
//           >
//             <a class="nav-link">Web Designing</a>
//           </Link>
//         </li>
//         <li class="nav-item">
//           <Link
//             to="software"
//             style={{ textDecoration: "none" }}
//             class="child-link"
//           >
//             <a class="nav-link">Software</a>
//           </Link>
//         </li>
//         <li class="nav-item">
//           <Link
//             to="mobileapp"
//             style={{ textDecoration: "none" }}
//             class="child-link"
//           >
//             <a class="nav-link">Mobile Application</a>
//           </Link>
//         </li>
//       </ul>
//     </div>
//   </p>
//   <p>
//     {/*  */}
//     <a
//       class="dr-a navbar-toggle d-flex justify-content-between"
//       data-bs-toggle="collapse"
//       data-bs-target="#show2"
//     >
//       Recruitment and Staffing
//       <i class="fas fa-angle-down mt-1"></i>
//     </a>
//     <div class="collapse " id="show2">
//       <ul class="navbar-nav d-flex flex-column">
//         <li class="nav-item">
//           <Link
//             to="payroll"
//             style={{ textDecoration: "none" }}
//             class="child-link"
//           >
//             <a class="nav-link">Payroll Outsourcing</a>
//           </Link>
//         </li>
//         <li class="nav-item">
//           <Link
//             to="recruit"
//             style={{ textDecoration: "none" }}
//             class="child-link"
//           >
//             <a class="nav-link">Recritment Srvices</a>
//           </Link>
//         </li>
//       </ul>
//     </div>
//   </p>
//   <p>
//     <a className="dr-a d-flex justify-content-between">
//       <Link
//         to="digitalmarketing"
//         class="dr-a"
//         style={{ textDecoration: "none" }}
//       >
//         Digital Marketing
//       </Link>
//       {/* <i class="fas fa-angle-right mt-1"></i> */}
//     </a>
//   </p>
// </div>

// {/*  */}
// </div>
