import react from "react";
import "./header.css";
import AndroidIcon from '@mui/icons-material/Android';
import aichip from "./assest/svgreact/aichip.png";
import "./ourproccess.css";

function Ourproccess() {
  return (
    <div class="text-center  bg-proccess mt-5">
      <div  class="container-fluid text-center p-4">
        <div class="py-3">
          <p  className="fifth-h1-2">Our Process</p>
          <p class="wwe-p">An effective process saves time, saves money, and produces value. We spent years crafting and honing a rock-solid<br></br> workflow for building and managing websites, and the refinement never stops.</p>
        </div>
        <div class="row container-fluid mt-5">
          <div class="col-md-3">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/mind-map--v2.png"/>
            <p class="text-secondary mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Planning</b></p>            </div>
          </div>
          <div class="col-md-3">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/design--v2.png"/>
            <p class="text-secondary mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Design</b></p>            </div>
          </div>
          <div class="col-md-3">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/combo-chart--v2.png"/>
            <p class="text-secondary  mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Development</b></p>            </div>
          </div>
          <div class="col-md-3">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/laptop-settings--v3.png"/>
            <p class="text-secondary  mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Testing</b></p>            </div>
          </div>
         
          <div class="col-md-4 mt-3">
            <div >
            <img src="https://img.icons8.com/ios-glyphs/50/000000/leadership--v2.png"/>
            <p class="text-secondary mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Training</b></p>            </div>
          </div>
          <div class="col-md-4 mt-4">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/rocket--v2.png"/>
            <p class="text-secondary  mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Launch</b></p>            </div>
          </div>
          <div class="col-md-4 mt-4">
            <div >
            <img src="https://img.icons8.com/ios/50/000000/wrench--v2.png"/>
                          <p class="text-secondary  mt-3"> <b style={{fontSize:"18px",textTransform:"uppercase"}}>Support</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Ourproccess;
