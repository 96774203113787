import react from "react";
import "./header.css";
import homewebapp from "./assest/seltam_img/homewebapp.jpg";
import homerecruitment from "./assest/seltam_img/homerecruitment.jpg";
import homedigitalmarketing from "./assest/seltam_img/homedigitalmarketing.jpg";

function Third() {
  return (
    <div class=" text-center">
      <div style={{}} class="text-center row">
        <div class="container-fluid my-5">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-2 justify-content-center  d-flex align-items-center">
                <h2 class="water">Web & App Development</h2>
              </div>
              <div class="col-lg-5  d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                  <h3 class="wwe-h3">Web & App Development</h3>
                </div>
                <div>
                  <p class="wwe-p-service my-3">
                    Seltam places significant focus on client satisfaction and
                    as such, your needs and goals are our top priority.We
                    develop websites that are not only visually attractive but
                    also fast loading, SEO-friendly and easy to navigate.
                  </p>
                  <p class="wwe-p-service my-3">
                    {" "}
                    We build outstanding mobile apps for all industries,
                    startups and enterprises.
                  </p>
                  <p class="wwe-p-service my-3">
                    Mobile app helps to make your business more profitable.
                  </p>
                </div>
              </div>
              <div class="col-lg-5 ">
                <img
                  src={homewebapp}
                  width="450px"
                  class="img-fluid homewebapp-img"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid my-5">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-5 ">
                <img
                  src={homedigitalmarketing}
                  width="450px"
                  class="img-fluid homewebapp-img"
                ></img>
              </div>
              <div class="col-lg-5  d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                  <h3 class="wwe-h3">Digital Marketing</h3>
                </div>
                <div>
                  <p class="wwe-p-service my-3">
                    You've spent a lot of time and money getting your business
                    to where it is today. Let us help you take your business to
                    the next level with our digital marketing solutions. We'll
                    make sure your company gets the exposure it needs to thrive.
                  </p>
                  <p class="wwe-p-service my-3">
                    {" "}
                    You've spent a lot of time and money getting your business
                    to where it is today. Let us help you take your business to
                    the next level with our digital marketing solutions. We'll
                    make sure your company gets the exposure it needs to thrive.
                  </p>
                </div>
              </div>
              <div class="col-lg-2 justify-content-center  d-flex align-items-center">
                <h2 class="water">Digital Marketing</h2>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="container-fluid my-5">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-2  justify-content-center  d-flex align-items-center">
                <h2 class="water"> Recruitment & Staffing Services</h2>
              </div>
              <div class="col-lg-5  d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                  <h3 class="wwe-h3"> Recruitment & Staffing Services</h3>
                </div>
                <div>
                  <p class="wwe-p-service my-3">
                    Our talent acquisition specialists support you at every
                    stage of the recruitment process. They identify experienced
                    candidates according to clients’ needs, no matter how niche
                    the role is.
                  </p>
                  <p class="wwe-p-service my-3">
                    {" "}
                    It is this level of service and expertise that allows us to
                    offer the most resourceful, workforce solutions in the
                    industry. It has also enabled us to work with the world’s
                    largest employers to staff flagship projects globally.
                  </p>
                </div>
              </div>
              <div class="col-lg-5 ">
                <img
                  src={homerecruitment}
                  width="450px"
                  class="img-fluid homewebapp-img"
                ></img>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="watermark">
          <h1 class="water-mark-1">Web & App Development</h1>
          <h1 class="water-mark-2">Digital Marketing</h1>
          <h1 class="water-mark-3">Recruitment and Staffing </h1>
        </div> */}
      </div>
    </div>
  );
}
export default Third;

// <div class="col-12 container-fluid mt-5 row d-flex justify-content-between  align-items-center ">
// <div className="three_img-1" class="col-sm">
//   <img
//     src={homedigitalmarketing}

//     style={{height:"400px"}}
//     class="img-fluid"
//   ></img>
// </div>
// <div class="col-sm-5">
//   <h2 className="three-head-h1" style={{ marginBottom: "20px" }}>
//     Digital Marketing
//   </h2>
//   <p class="paragraph">
//     You've spent a lot of time and money getting your business to
//     where it is today. Let us help you take your business to the next
//     level with our digital marketing solutions. We'll make sure your
//     company gets the exposure it needs to thrive. We promise each and
//     every one of our customers a unique, customized, and
//     client-focused approach to most accurately and creatively assess
//     their business, their needs, and their target audience.
//   </p>
// </div>
// </div>

// <div class="col-12 mt-5 container-fluid row d-flex justify-content-between  align-items-center ">
// <div class="col-sm-5">
//   <h2 className="three-head-h1" style={{ marginBottom: "20px" }}>
//     Recruitment & Staffing Services
//   </h2>
//   <p class="paragraph">
//     Our talent acquisition specialists support you at every stage of
//     the recruitment process. They identify experienced candidates
//     according to clients’ needs, no matter how niche the role is. It
//     is this level of service and expertise that allows us to offer the
//     most resourceful, workforce solutions in the industry. It has also
//     enabled us to work with the world’s largest employers to staff
//     flagship projects globally.
//   </p>
// </div>
// <div className="" class="col-sm">
//   <img
//     src={homerecruitment}
//     class="img-fluid"
//     style={{ height: "370px" }}
//   ></img>
// </div>
// </div>
